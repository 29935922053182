import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`V1.1`}</p>
    </blockquote>
    <h2>{`Introduction`}</h2>
    <h3>{`Purpose and scope`}</h3>
    <p>{`The purpose of this document is to outline CoreMedia's policies and procedures for classifying and handling confidential, sensitive, and proprietary information. This document applies to all employees, contractors, and other individuals with access to CoreMedia's information resources.`}</p>
    <h3>{`Definitions`}</h3>
    <p>{`For the purposes of this document, the following terms are defined as follows:`}</p>
    <ul>
      <li parentName="ul">{`Confidential information: Information that is not intended for public disclosure and must be protected against unauthorized access, use, or disclosure. Examples include business plans, financial reports, and employee personal data.`}</li>
      <li parentName="ul">{`Sensitive information: Information that requires a higher level of protection than general business information, but may be released to authorized parties on a need-to-know basis. Examples include technical data, research and development results, and intellectual property.`}</li>
      <li parentName="ul">{`Proprietary information: Information that is owned or controlled by CoreMedia and is treated as a valuable asset. Examples include trade secrets, patents, and trademarks.`}</li>
    </ul>
    <h3>{`References`}</h3>
    <p>{`This document is based on the requirements of ISO 9001:2015, "Quality management systems - Requirements." `}</p>
    <h2>{`Information classification`}</h2>
    <h3>{`Types of information`}</h3>
    <p>{`CoreMedia's information is classified into the following categories:`}</p>
    <ul>
      <li parentName="ul">{`Confidential information: Information that is not intended for public disclosure and must be protected against unauthorized access, use, or disclosure. Examples include business plans, financial reports, and employee personal data.`}</li>
      <li parentName="ul">{`Sensitive information: Information that requires a higher level of protection than general business information, but may be released to authorized parties on a need-to-know basis. Examples include technical data, research and development results, and intellectual property.`}</li>
      <li parentName="ul">{`Proprietary information: Information that is owned or controlled by CoreMedia and is treated as a valuable asset. Examples include trade secrets, patents, and trademarks.`}</li>
      <li parentName="ul">{`General business information: Information that is not considered confidential, sensitive, or proprietary, but is still important to the operation of CoreMedia's business. Examples include general business documents, such as memos, emails, and reports.`}</li>
      <li parentName="ul">{`Internal information: This is information that is only intended for use within an organization and is not meant to be shared outside of it. Examples include internal reports, policies, and procedures.`}</li>
      <li parentName="ul">{`Public information: This is information that is freely available to anyone and does not require any special access or permission to obtain. Examples include news articles, public records, and other publicly available data.`}</li>
    </ul>
    <p>{`The classification of information is based on the level of protection required to safeguard the confidentiality, integrity, and availability of the information. CoreMedia's information classification system ensures that all employees, contractors, and other individuals with access to CoreMedia's information resources understand the importance of protecting classified information and know how to handle it appropriately.`}</p>
    <h3>{`Criteria for classification`}</h3>
    <p>{`Information is classified based on the following criteria:`}</p>
    <ul>
      <li parentName="ul">{`Legal and regulatory requirements: Some types of information, such as personal data and financial information, are subject to specific legal and regulatory requirements that dictate how they must be protected. CoreMedia's information classification system takes these requirements into account when determining the appropriate level of protection for the information.`}</li>
      <li parentName="ul">{`Potential impact on CoreMedia's business and reputation: Some information, such as business plans and research and development results, may have a significant impact on CoreMedia's business and reputation if it were to be disclosed to unauthorized parties. This information is classified at a higher level to ensure that it is properly protected.`}</li>
      <li parentName="ul">{`Level of sensitivity and value to CoreMedia and its stakeholders: Some information, such as intellectual property and trade secrets, may be highly sensitive or valuable to CoreMedia and its stakeholders. This information is classified at a higher level to ensure that it is properly protected.`}</li>
    </ul>
    <p>{`CoreMedia's information classification system is designed to ensure that all information is protected at an appropriate level, based on the potential impact of a breach, the legal and regulatory requirements, and the sensitivity and value of the information.`}</p>
    <h3>{`Responsibilities for classification`}</h3>
    <p>{`The following individuals are responsible for classifying information:`}</p>
    <ul>
      <li parentName="ul">{`Information owners: Employees who create or receive information and are responsible for ensuring that it is properly classified and protected. Information owners are responsible for determining the appropriate classification level for the information and for ensuring that the information is stored and handled in accordance with CoreMedia's policies and procedures.`}</li>
      <li parentName="ul">{`Information custodians: Employees who are responsible for managing and protecting information in specific areas or functions. Information custodians are responsible for ensuring that the information in their areas or functions is properly classified and protected, and for providing guidance to other employees on how to handle and protect the information.`}</li>
      <li parentName="ul">{`Information security officer: The employee responsible for overall management of CoreMedia's information security program. The information security officer is responsible for establishing and maintaining CoreMedia's information classification system, and for ensuring that all employees and contractors understand and comply with the policies and procedures related to the protection of classified information.`}</li>
    </ul>
    <p>{`CoreMedia's information classification system is designed to ensure that all employees, contractors, and other individuals with access to CoreMedia's information resources understand their responsibilities for protecting classified information and know how to handle it appropriately. `}</p>
    <h2>{`Handling and protection of classified information`}</h2>
    <h3>{`Physical security measures`}</h3>
    <p>{`Physical security measures are implemented to protect against unauthorized access to classified information. These measures may include:`}</p>
    <ul>
      <li parentName="ul">{`Locked cabinets and storage areas: Classified information is stored in locked cabinets or secure storage areas to prevent unauthorized access.`}</li>
      <li parentName="ul">{`Controlled access to buildings and facilities: Access to buildings and facilities where classified information is stored or accessed is controlled through the use of secure entrances, badges, and other security measures.`}</li>
      <li parentName="ul">{`Secure transport of classified information: Classified information is transported in secure containers or through secure means, such as encrypted electronic transmission, to prevent unauthorized access.`}</li>
    </ul>
    <p>{`CoreMedia's physical security measures are designed to ensure that classified information is protected against unauthorized access, use, or disclosure. All employees and contractors with access to classified information are responsible for complying with these measures and for reporting any security incidents or concerns to the appropriate authorities.`}</p>
    <h3>{`Electronic security measures`}</h3>
    <p>{`Electronic security measures are implemented to protect against unauthorized access to classified information stored electronically. These measures may include:`}</p>
    <ul>
      <li parentName="ul">{`Passwords: Classified information is protected with strong, unique passwords to prevent unauthorized access.`}</li>
      <li parentName="ul">{`Encryption: Classified information is encrypted to protect it against unauthorized access or interception during transmission or storage.`}</li>
      <li parentName="ul">{`Access controls: Access to classified information is controlled through the use of permissions, roles, and other access controls to ensure that only authorized individuals have access to the information.`}</li>
    </ul>
    <p>{`CoreMedia's electronic security measures are designed to ensure that classified information is protected against unauthorized access, use, or disclosure. All employees and contractors with access to classified information are responsible for complying with these measures and for reporting any security incidents or concerns to the appropriate authorities.`}</p>
    <h3>{`Access controls`}</h3>
    <p>{`Access to classified information is restricted to authorized individuals on a need-to-know basis. Access controls are implemented to ensure that only authorized individuals have access to the information. These controls may include:`}</p>
    <ul>
      <li parentName="ul">{`Permissions: Classified information is assigned to specific individuals or groups, and access is granted based on the individual's or group's role and responsibilities.`}</li>
      <li parentName="ul">{`Roles: Individuals are assigned specific roles that define the types of information they can access and the actions they can perform with the information.`}</li>
      <li parentName="ul">{`Access logs: Access to classified information is logged to allow for the tracking of access and any suspicious activity.`}</li>
    </ul>
    <p>{`CoreMedia's access controls are designed to ensure that classified information is protected against unauthorized access, use, or disclosure. All employees and contractors with access to classified information are responsible for complying with these controls and for reporting any security incidents or concerns to the appropriate authorities. `}</p>
    <h2>{`Records management`}</h2>
    <h3>{`Requirements for records`}</h3>
    <p>{`For CoreMedia, the "Requirements for records" section in the chapter on "Records management" might read as follows:`}</p>
    <p>{`Records related to classified information must be properly managed to ensure that they are accurate, complete, and secure. Records may include paper documents, electronic files, and other types of media. The following requirements apply to records related to classified information:`}</p>
    <ul>
      <li parentName="ul">{`Accuracy: Records must be accurate and reflect the actual status of the information they contain.`}</li>
      <li parentName="ul">{`Completeness: Records must be complete and include all relevant information.`}</li>
      <li parentName="ul">{`Security: Records must be protected against unauthorized access, use, or disclosure.`}</li>
      <li parentName="ul">{`Retention: Records must be retained for the required period of time, as specified in the record retention schedule (see Appendix A).`}</li>
    </ul>
    <p>{`CoreMedia's records management system is designed to ensure that records related to classified information are properly managed to safeguard the confidentiality, integrity, and availability of the information. All employees and contractors with access to classified information are responsible for complying with these requirements and for reporting any security incidents or concerns to the appropriate authorities.`}</p>
    <h3>{`Retention and disposal of records`}</h3>
    <p>{`Records related to classified information must be retained for the required period of time and then disposed of in a secure manner. The retention period for each type of record is specified in the record retention schedule (see Appendix A). When the retention period for a record has expired, the record must be disposed off in a secure manner to prevent unauthorized access, use, or disclosure.`}</p>
    <p>{`Disposal methods may include shredding paper records, wiping electronic media, and other methods to ensure that the information is irretrievable. All employees and contractors with access to classified information are responsible for complying with the record retention and disposal requirements and for reporting any security incidents or concerns to the appropriate authorities.`}</p>
    <p>{`CoreMedia's records management system is designed to ensure that records related to classified information are properly retained and disposed of to safeguard the confidentiality, integrity, and availability of the information.`}</p>
    <h3>{`Control of records`}</h3>
    <p>{`Records related to classified information must be properly controlled to ensure that they are accurate, complete, and secure. This includes:`}</p>
    <ul>
      <li parentName="ul">{`Creating and updating records: Records must be created and updated in a timely and accurate manner. Any changes to the records must be documented and tracked.`}</li>
      <li parentName="ul">{`Storing records: Records must be stored in a secure location, such as a locked cabinet or secure storage area.`}</li>
      <li parentName="ul">{`Accessing records: Access to records must be restricted to authorized individuals on a need-to-know basis. Access controls, such as permissions and roles, must be implemented to ensure that only authorized individuals have access to the records.`}</li>
      <li parentName="ul">{`Retrieving records: Records must be retrieved in a timely and secure manner. Any access to or handling of the records must be logged to allow for the tracking of access and any suspicious activity.`}</li>
    </ul>
    <p>{`CoreMedia's control of records is designed to ensure that records related to classified information are properly managed to safeguard the confidentiality, integrity, and availability of the information. All employees and contractors with access to classified information are responsible for complying with these controls and for reporting any security incidents or concerns to the appropriate authorities.`}</p>
    <h2>{`Review and revision`}</h2>
    <h3>{`Review of the information classification system`}</h3>
    <p>{`CoreMedia's information classification system is reviewed on a regular basis to ensure that it is effective and meets the organization's needs. The review process may include:`}</p>
    <ul>
      <li parentName="ul">{`Assessing the effectiveness of the system: The system is assessed to determine whether it is effectively protecting classified information and meeting the organization's needs.`}</li>
      <li parentName="ul">{`Identifying any weaknesses or gaps in the system: Any weaknesses or gaps in the system are identified and addressed.`}</li>
      <li parentName="ul">{`Evaluating any changes in the organization's needs or environment: Any changes in the organization's needs or environment, such as new legal or regulatory requirements, are evaluated to determine whether the system needs to be revised.`}</li>
    </ul>
    <p>{`The review of the information classification system is an ongoing process to ensure that it remains effective and meets the organization's needs. All employees and contractors with access to classified information are responsible for participating in the review process and for reporting any security incidents or concerns to the appropriate authorities.`}</p>
    <h3>{`Revision of the information classification system`}</h3>
    <p>{`If changes are needed to CoreMedia's information classification system, they are made through a formal revision process. The revision process may include:`}</p>
    <ul>
      <li parentName="ul">{`Identifying the need for change: Changes to the system may be identified through the review process, or as a result of new legal or regulatory requirements, changes in the organization's needs or environment, or other factors.`}</li>
      <li parentName="ul">{`Determining the scope of the revision: The scope of the revision is determined based on the nature and extent of the changes needed.`}</li>
      <li parentName="ul">{`Developing a revision plan: A plan is developed to outline the steps that will be taken to revise the system.`}</li>
      <li parentName="ul">{`Implementing the revision: The revision is implemented according to the plan, with appropriate communication and training provided to employees and contractors.`}</li>
    </ul>
    <p>{`The revision of the information classification system is an ongoing process to ensure that it remains effective and meets the organization's needs. All employees and contractors with access to classified information are responsible for participating in the revision process and for complying with any changes to the system. `}</p>
    <h2>{`Appendices`}</h2>
    <h3>{`Classification levels and examples`}</h3>
    <p>{`CoreMedia's information classification system includes the following levels of classification:`}</p>
    <ul>
      <li parentName="ul">{`Confidential: Information that is not intended for public disclosure and must be protected against unauthorized access, use, or disclosure. Examples include business plans, financial reports, and employee personal data.`}</li>
      <li parentName="ul">{`Sensitive: Information that requires a higher level of protection than general business information, but may be released to authorized parties on a need-to-know basis. Examples include technical data, research and development results, and intellectual property.`}</li>
      <li parentName="ul">{`Proprietary: Information that is owned or controlled by CoreMedia and is treated as a valuable asset. Examples include trade secrets, patents, and trademarks.`}</li>
      <li parentName="ul">{`General business: Information that is not considered confidential, sensitive, or proprietary, but is still important to the operation of CoreMedia's business. Examples include general business documents, such as memos, emails, and reports.`}</li>
      <li parentName="ul">{`Internal information: This is information that is only intended for use within an organization and is not meant to be shared outside of it. Examples include internal reports, policies, and procedures.`}</li>
      <li parentName="ul">{`Public information: This is information that is freely available to anyone and does not require any special access or permission to obtain. Examples include news articles, public records, and other publicly available data.`}</li>
    </ul>
    <p>{`The classification of information is based on the level of protection required to safeguard the confidentiality, integrity, and availability of the information. CoreMedia's information classification system ensures that all employees, contractors, and other individuals with access to CoreMedia's information resources understand the importance of protecting classified information and know how to handle it appropriately.`}</p>
    <h3>{`Record retention schedule`}</h3>
    <p>{`The following table outlines the retention periods for records related to classified information at CoreMedia:`}</p>
    <p>{` Record Type| | Retention Period|
Customers service data| | 0-18 months (upon request)|
Business plans| | 5 years|
Financial reports| | 7 years|
Employee personal data| | 3 years after employment ends|
Technical data| | 10 years|
Research and development results| | 5 years|
Intellectual property| | Indefinitely|
Trade secrets| | Indefinitely|
Patents| | Until patent expires|
Trademarks| | Until trademark expires|
General business documents| | 3 years|`}</p>
    <p>{`The retention periods for records are based on legal and regulatory requirements, the potential impact of a breach, and the sensitivity and value of the information. CoreMedia's record retention schedule is reviewed and revised on a regular basis to ensure that it meets the organization's needs and complies with any changes in legal or regulatory requirements. All employees and contractors with access to classified information are responsible for complying with the record retention requirements and for reporting any security incidents or concerns to the appropriate authorities.`}</p>
    <h2>{`Attachments`}</h2>
    <p>{`This document does not contain any attachments. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      